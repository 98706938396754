import React, { ReactElement, useEffect, useState } from 'react'
import CreatableSelect from 'react-select/creatable'
import {
  InputFeedback,
  InputFeedbackStatus,
} from '@components/Basic/InputFeedback'
import {
  CustomMenuList,
  CustomMultiValue,
  CustomOption,
} from '@components/Form/MultiCreatableCustom/ComponentOverrides'
import { ApiFormulasSchemaResponseData } from 'BackendApi'
import {
  customStyles,
  SourceIcon,
} from '@components/Form/MultiCreatableCustom/styled'
import { MappedField, MappedFieldMapping, MappedFieldType } from 'Nbee'
import {
  createDefaultValues,
  createOptions,
  convertToMapping,
} from '@components/Form/MultiCreatableCustom/utils'
import { useTranslation } from 'react-i18next'
import { SelectInstance } from 'react-select'

export type SelectValueMulti = {
  label: string
  value: string
  selectId?: string
  fieldType?: MappedFieldType
  formulaDescription?: string
  formulaCategory?: string
  isNewFormula?: boolean
  sourceLogoComponent?: ReactElement
}

export type CustomCreatableAdditionalProps = {
  handleTabChange?: (tabName: TabType) => void
  activeTab?: TabType
  hasPicklist?: boolean
  sourceLogoComponent?: ReactElement
  userHasFormulaEnabled?: boolean
  onRedirectToPricing?: (redirect: boolean) => void
}

export type TabType = 'fields' | 'formulas' | 'custom'

interface Props {
  mappedField?: MappedField
  fieldIndex?: number
  onMappingChange?: (mapping: MappedFieldMapping[]) => void
  onFormulaSelect?: (mappingItem: MappedFieldMapping) => void
  onRedirectToPricing?: (redirect: boolean) => void
  sourceOptions?: SelectValueMulti[]
  picklistOptions?: SelectValueMulti[]
  userHasFormulaEnabled?: boolean
  formulaSchema?: ApiFormulasSchemaResponseData[]
  sourceLogoUri?: string
  placeholder?: string
  isLoading?: boolean
  isDisabled?: boolean
  $status?: InputFeedbackStatus
  dataTestId?: string
}

export const MultiCreatableCustom: React.FC<Props> = ({
  mappedField,
  fieldIndex = 0, // default value must be 0
  onMappingChange,
  onFormulaSelect,
  onRedirectToPricing,
  sourceOptions,
  picklistOptions,
  userHasFormulaEnabled,
  formulaSchema,
  sourceLogoUri,
  placeholder,
  isLoading,
  isDisabled,
  $status,
  dataTestId,
}) => {
  const [selectedOptions, setSelectedOptions] = useState<SelectValueMulti[]>([])
  const [menuIsOpen, setMenuIsOpen] = useState(false)
  const { t } = useTranslation()

  const hasPicklist =
    picklistOptions &&
    mappedField?.picklist &&
    mappedField?.picklist?.length > 0
  const defaultActiveTab = hasPicklist ? 'custom' : 'fields'
  const [activeTab, setActiveTab] = useState<TabType>(defaultActiveTab)

  const options = createOptions(
    sourceOptions || [],
    picklistOptions || [],
    formulaSchema || [],
    activeTab
  )
  const defaultValues =
    createDefaultValues(mappedField?.mapping || [], sourceOptions || [], t) ||
    []

  const optionsDisabled = !userHasFormulaEnabled && activeTab === 'formulas'

  const sourceLogoComponent = (
    <SourceIcon src={sourceLogoUri} alt='Source logo' />
  )

  // Wrapper function to update both local (react-select) and parent (formik) state
  const handleMappingChange = (
    newOptions: SelectValueMulti[], // New selected options
    selectedOption?: SelectValueMulti // The option that was selected/deselected (if any)
  ) => {
    // console.log('handle mapping change is called', newOptions, selectedOption)
    // Convert the new selected options to an array of MappedFieldMapping objects for the Formik context
    if (!onMappingChange) return
    const newMapping = convertToMapping(
      newOptions,
      mappedField ?? { mappingType: 'manual', mapping: [] }
    )

    setSelectedOptions(newOptions) // Update local state with the new options
    onMappingChange(newMapping) // Notify parent component with new mapping array

    if (!onFormulaSelect) return
    // Check if the selected option is a formula and pass the mapping object to the parent component
    if (selectedOption?.fieldType === 'formula') {
      const newMappingWithFormula: MappedFieldMapping = {
        id: selectedOption.selectId,
        sourceFieldId: null,
        fieldType: 'formula',
        text: null,
        formula: {
          id: selectedOption.value,
          params:
            mappedField?.mapping.find((m) => m.id === selectedOption.selectId)
              ?.formula?.params || [],
          isNew: selectedOption.isNewFormula ?? true, // Set isNew to true if it's a new formula, otherwise false when editing an existing one
        },
      }
      onFormulaSelect(newMappingWithFormula)
    }
  }

  const handleTabChange = (tabName: TabType) => {
    setActiveTab(tabName)
  }

  const selectRef = React.useRef<SelectInstance>(null)
  const handleKeyDown = (e: React.KeyboardEvent<HTMLElement>) => {
    // Dynamically determine the available tabs based on conditions
    const availableTabs: TabType[] = ['fields', 'formulas'] // Always available
    if (hasPicklist) availableTabs.unshift('custom') // Add 'custom' at the beginning if available

    const currentTabIndex = availableTabs.indexOf(activeTab)

    if (e.key === 'Tab') {
      e.preventDefault()
      // Move to the next tab (cyclically)
      const nextTabIndex = (currentTabIndex + 1) % availableTabs.length
      handleTabChange(availableTabs[nextTabIndex])
    }
    if (e.key === 'Backspace' && selectRef.current?.props.inputValue === '') {
      e.preventDefault()
      // console.log('selectValue', selectRef.current)
      handleMappingChange(selectedOptions.slice(0, -1))
    }
    if (e.key === ' ' && selectRef.current?.props.inputValue === '') {
      e.preventDefault()
      handleMappingChange([
        ...selectedOptions,
        {
          label: 'SPACE',
          value: ' ',
          fieldType: 'text',
          selectId: `${fieldIndex}-SPACE-${selectedOptions.length}`,
        },
      ])
    }
  }

  const extraProps: CustomCreatableAdditionalProps = {
    handleTabChange,
    activeTab,
    hasPicklist,
    sourceLogoComponent,
    userHasFormulaEnabled,
    onRedirectToPricing,
  }

  // Update selectedOptions when mappedField changes
  // This is necessary to keep the selected options in sync with the mappedField
  // (e.g. when the user adds a new formula, we want to display the label including the selected
  // field from the FormulaModal)
  useEffect(() => {
    const newOptions = createDefaultValues(
      mappedField?.mapping || [],
      sourceOptions || [],
      t
    )
    setSelectedOptions(newOptions as SelectValueMulti[])
  }, [mappedField?.mapping])

  return (
    <div data-testid={dataTestId}>
      <CreatableSelect
        ref={selectRef}
        options={options}
        styles={customStyles}
        components={{
          MultiValue: CustomMultiValue,
          IndicatorsContainer: () => null, // disable indicators
          Option: CustomOption,
          MenuList: CustomMenuList,
          MultiValueRemove: () => null, // disable default MultiValueRemove component
        }}
        value={selectedOptions}
        defaultValue={defaultValues}
        placeholder={isLoading ? 'Loading...' : placeholder}
        onKeyDown={handleKeyDown}
        openMenuOnClick={true}
        menuIsOpen={menuIsOpen}
        onMenuOpen={() => setMenuIsOpen(true)}
        onMenuClose={() => setMenuIsOpen(false)}
        hideSelectedOptions={false}
        isDisabled={isLoading || isDisabled}
        isMulti
        isClearable={false}
        isOptionSelected={() => false}
        isOptionDisabled={() => optionsDisabled || false}
        isValidNewOption={(inputValue) => {
          // Necessary to allow adding value with the same text
          return inputValue.trim().length > 0
        }}
        onChange={(selectedValue, actionMeta) => {
          switch (actionMeta.action) {
            case 'select-option': {
              const selectedOption = actionMeta.option as SelectValueMulti
              // when selectedOption is an array, is because the editFormula button was clicked.
              // Otherwise, a new option has been added
              // console.log('selectedOption', selectedOption)
              if (selectedOption.value) {
                // If we're adding a new option, we want to generate a new selectId
                // to avoid conflicts with existing options,
                // and to update the local and parent states
                // console.log('selectedOption', selectedOption)
                const mappingIndex = selectedOptions.length
                const identifier = selectedOption.value
                // Generate unique identifier for the new option
                const selectId = `${fieldIndex}-${identifier}-${mappingIndex}`

                const updatedOption = {
                  ...selectedOption,
                  selectId,
                }

                // Update selectedOptions with the new or updated option
                handleMappingChange(
                  [...selectedOptions, updatedOption],
                  updatedOption
                )
              } else {
                // we are editing an existing formula, so we already have the selectId
                handleMappingChange(
                  [...selectedOptions],
                  selectedValue as unknown as SelectValueMulti
                )
              }
              break
            }

            case 'deselect-option': {
              // Filter out the deselected option using the selectId previously generated,
              // then update local and parent states
              handleMappingChange(
                selectedOptions.filter(
                  (option) =>
                    option.selectId !==
                    (selectedValue as SelectValueMulti).selectId
                )
              )
              break
            }

            case 'create-option': {
              // Handle new 'text' options created by the user
              const newOption = {
                label: (actionMeta.option as SelectValueMulti).label,
                value: (actionMeta.option as SelectValueMulti).value,
                fieldType: 'text' as MappedFieldType, // Default to 'text' for new custom options
                isFormula: false,
                selectId: `${fieldIndex}-${
                  (actionMeta.option as SelectValueMulti).value
                }-${selectedOptions.length}`, // Generate a new selectId for the custom option
              }
              // update local and parent states
              handleMappingChange([...selectedOptions, newOption])
              break
            }
          }
        }}
        {...extraProps}
      />
      {$status && <InputFeedback $status={$status} />}
    </div>
  )
}
