import React, { useState } from 'react'
import { Button } from '@components/Basic/ButtonNbe'
import {
  BridgeSettingsWrapper,
  StepCompletedWrapper,
  StepImage,
} from './styled'
import { PageEditor } from '@app/layouts/PageEditor'
import { Panel } from '@components/Panel'
import { useTranslation } from 'react-i18next'
import { makeNbeeSteps } from '@features/nbee/utils'
import { useHistory } from 'react-router-dom'
import { appRoutes } from '@app/routes'
import FinishImage from '@assets/images/illustrations/nbee-completed.svg'
import { EmailReceipt } from '@features/nbee/EmailReceipt'
import { isInIframe } from '@app/utils/isInFrame'
import Confetti from '@components/Confetti'
import { useQueryClient } from 'react-query'

interface Props {
  bridgeName: string
  bridgeId: string
  sourceName?: string
  destinationName?: string
  redirectUri?: string
  isTestEnabledProp?: boolean
}

export const StepCompleted: React.FC<Props> = ({
  bridgeId,
  bridgeName,
  sourceName,
  destinationName,
  redirectUri,
  isTestEnabledProp,
}) => {
  const { t } = useTranslation()
  const history = useHistory()
  const steps = makeNbeeSteps(2, bridgeId)
  const isEmbedded = isInIframe()

  const queryClient = useQueryClient()

  return (
    <PageEditor
      steps={steps}
      activeStep={2}
      topbarValue={bridgeName}
      hasTopBar={!isEmbedded}
      bridgeIdProp={bridgeId}
      IsTestEnableProp={isTestEnabledProp}
      backlink={{
        url: '/bridges',
        label: t('nbee.bridgeBuilder.exitNbee'),
      }}
    >
      <Panel
        progressBarPercentage={isEmbedded ? 100 : undefined}
        body={
          <div>
            <Confetti />
            <StepCompletedWrapper>
              <h3>{t('nbee.bridgeBuilder.step3SuccessHeader')}</h3>
              <p>
                {t('nbee.bridgeBuilder.step3SuccessBody', {
                  sourceName: sourceName,
                  destinationName: destinationName,
                })}
              </p>

              <div style={{ marginTop: '2em' }}>
                <a
                  rel='noreferrer'
                  href={redirectUri}
                  target={isInIframe() ? '_blank' : undefined}
                >
                  <Button $variant={'primary'}>
                    {t('nbee.bridgeBuilder.exploreLeadsBridge')}
                  </Button>
                </a>
              </div>

              <StepImage
                src={FinishImage}
                alt={t('nbee.bridgeBuilder.step3Title')}
              />
            </StepCompletedWrapper>
          </div>
        }
        footer={{
          left: (
            <Button
              type={'button'}
              $variant={'link-secondary'}
              onClick={() => {
                // we want to invalidate the permission checks query when the user goes back from here. This way the information will be refetched once the user lands on step2
                queryClient.invalidateQueries(['permission-checks'])
                history.push(appRoutes.nbeeStep2.makeUrl(bridgeId))
              }}
            >
              {t('nbee.fieldsMapping.goPreviewStep')}
            </Button>
          ),
          right: (
            <div style={{ display: 'flex', gap: '1rem' }}>
              <Button
                type={'button'}
                $variant={'secondary'}
                onClick={() => {
                  history.push(appRoutes.nbeeBridgeChooserStep1.makeUrl())
                }}
              >
                {t('nbee.fieldsMapping.makeNewBridge')}
              </Button>
            </div>
          ),
        }}
      />
    </PageEditor>
  )
}
